import { plainBtnStyles } from '@/buttons/Plain';
import { css } from '@emotion/react';
import { theme } from './styles/GlobalStyles';

const dynamicStyles = css`
    &[aria-current='page'] {
        :before {
            width: 32px;
        }
    }

    &.current-nav-category {
        :before {
            width: 32px;
        }
    }

    &[aria-expanded='true'] {
        > svg {
            transform: rotate(180deg);
        }

        ::before {
            width: 100%;
        }

        &.desktop-nav-btn {
            ::before {
                width: calc(100% - 18px);
            }
        }
    }

    @media (pointer: fine) {
        :hover {
            ::before {
                width: 100%;
            }

            &.desktop-nav-btn {
                ::before {
                    width: calc(100% - 18px);
                }
            }
        }

        :focus-visible {
            ::before {
                width: 100%;
            }

            &.desktop-nav-btn {
                ::before {
                    width: calc(100% - 18px);
                }
            }
        }
    }

    @media (min-width: 1024px) {
        &.desktop-sub-nav-link {
            :hover {
                color: ${theme.colors.blue4};
                background-color: ${theme.colors.blue1};
            }

            :focus-visible {
                color: ${theme.colors.blue4};
                background-color: ${theme.colors.blue1};
            }
        }
    }
`;

export const navLinkStyles = css`
    ${dynamicStyles};
    text-transform: capitalize;
    color: ${theme.colors.gray1};
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 150%;
    transition: color 0.3s ease-in-out;
    margin-bottom: 24px;
    padding-bottom: 6px;
    position: relative;
    display: flex;

    ::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 3px;
        border-radius: 15px;
        background-color: ${theme.colors.blue3};
        transition: width 0.3s ease-in-out;
    }

    @media (min-width: 1024px) {
        margin-bottom: 0 !important;
        margin-right: 40px;
    }
`;

export const subNavLinkStyles = css`
    ${navLinkStyles};

    @media (min-width: 1024px) {
        color: ${theme.colors.gray7};
        margin-bottom: 24px;
        padding: 16px 24px;
        min-width: 300px;
        margin: 0 !important;
        transition:
            color 0.3s ease-in-out,
            background-color 0.3s ease-in-out;

        ::before {
            content: none !important;
        }
    }
`;

export const navBtnStyles = css`
    ${plainBtnStyles};
    ${navLinkStyles};
    line-height: 150%;

    > svg {
        margin-left: 16px;
        transition: transform 0.3s ease-in-out;
        flex-shrink: 0;
        transform: rotate(-90deg);

        path {
            transition: fill 0.3s ease-in-out;
            fill: ${theme.colors.gray1};
        }
    }

    @media (min-width: 1024px) {
        > svg {
            transform: rotate(0deg);
        }
    }
`;

export const subNavBtnStyles = css`
    ${plainBtnStyles};
    ${subNavLinkStyles};
    font-weight: 400;
    align-items: center;
    width: 100%;
    text-align: left;

    &.current-sub-category {
        color: ${theme.colors.blue5};
    }

    > svg {
        transform: rotate(-90deg);
        margin-left: 10px;
    }
`;
